var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("sweet-alert-basic"),
          _c("sweet-alert-position"),
          _c("sweet-alert-animation"),
          _c("sweet-alert-types"),
          _c("sweet-alert-option"),
          _c("sweet-alert-confirm-option")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }