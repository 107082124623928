var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Position" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePosition) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(" You can specify position of your alert with "),
        _c("code", [
          _vm._v(
            "{position : 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' }"
          )
        ]),
        _vm._v(" in js. ")
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: { click: _vm.topStart }
            },
            [_vm._v(" Top Start ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: { click: _vm.topEnd }
            },
            [_vm._v(" Top End ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: { click: _vm.bottomStart }
            },
            [_vm._v(" Bottom Starts ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: { click: _vm.bottomEnd }
            },
            [_vm._v(" Bottom End ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }